import TranslationCountries from '@/assets/json/translation-countries.json'
import TranslationContinents from '@/assets/json/translation-continents.json'

export const translateCountry = (text) => TranslationCountries[text] || text

export const translateContinent = (text) => TranslationContinents[text] || text

export const getContinents = () => Object.keys(TranslationContinents)

export default {
  translateCountry,
  translateContinent,
  getContinents
}
