<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-width="360"
    max-width="360"
    max-height="50vh"
    offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        depressed
        block
        :ripple="false"
        style="width: fit-content; background-color: white;"
        :disabled="loading"
        v-bind="attrs"
        v-on="on">
        <span>
          ตัวกรอง
        </span>
        <v-icon>
          mdi-filter-variant
        </v-icon>
      </v-btn>
    </template>

    <v-card
      rounded="lg"
      class="pa-4">
      <span>
        ทวีป
      </span>
      <v-divider class="my-1" />
      <div
        class="mb-4 d-flex flex-row flex-wrap"
        style="gap: 8px;">
        <v-checkbox
          v-for="continent in continents"
          :key="continent.value"
          v-model="query.continents"
          :value="continent.value"
          :label="continent.text"
          hide-details
          class="mt-0" />
      </div>
      <span>
        ประเทศ
      </span>
      <v-divider class="my-1" />
      <div
        class="mb-4 d-flex flex-row flex-wrap"
        style="gap: 8px;">
        <v-checkbox
          v-for="country in filterCountries"
          :key="country.value"
          v-model="query.countries"
          :value="country.value"
          :label="country.text"
          hide-details
          class="mt-0" />
      </div>
      <template v-if="role === 'administrator'">
        <span>
          เวนเดอร์
        </span>
        <v-divider class="my-1" />
        <div class="mb-4">
          <v-autocomplete
            v-model="query.vendorId"
            :items="vendors"
            item-text="text"
            item-value="value"
            outlined
            dense
            placeholder="โปรดเลือก"
            hide-details
            required
            :disabled="loading"
            class="mt-3" />
        </div>
      </template>
      <span>
        สถานะ
      </span>
      <v-divider class="my-1" />
      <div class="mb-4">
        <v-select
          v-model="query.status"
          :items="status"
          item-text="text"
          item-value="value"
          outlined
          dense
          placeholder="โปรดเลือก"
          hide-details
          required
          :disabled="loading"
          class="mt-3" />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import CountriesProvider from '@/resources/countries.provider'
import VendorsProvider from '@/resources/vendors.provider'
import { translateCountry, translateContinent } from '@/utils/translates.utils'

const CountriesService = new CountriesProvider()
const VendorsService = new VendorsProvider()

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    menu: false,
    continents: [],
    countries: [],
    vendors: [],
    status: [
      {
        text: 'ทั้งหมด',
        value: null
      },
      {
        text: 'เปิด',
        value: 'available'
      },
      {
        text: 'ปิด',
        value: 'unavailable'
      }
    ]
  }),
  computed: {
    query: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    },
    filterCountries () {
      return this.query.continents.length ? this.countries.filter((c) => this.query.continents.includes(c.continent)) : this.countries
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.getAttributes()
      }
    },
    'query.continents': {
      handler () {
        this.query.countries = this.query.countries.filter((c) => this.filterCountries.some((f) => f.value === c))
      },
      deep: true
    }
  },
  mounted () {
    if (this.user) {
      this.getAttributes()
    }
  },
  methods: {
    async getAttributes () {
      try {
        this.loading = true

        await Promise.all([
          this.getVendors(),
          this.getCountries()
        ])
      } catch (error) {
        console.error('getAttributes', error)
      } finally {
        this.loading = false
      }
    },
    async getCountries () {
      try {
        this.loading = true

        const { data } = await CountriesService.getItems({
          page: 1,
          limit: 99999
        })

        const uniqueContinents = [...new Set(data.results.map((c) => c.continent))]

        this.continents = uniqueContinents.map((c) => ({
          text: translateContinent(c),
          value: c
        }))
        this.countries = data.results
          .map((c) => ({
            text: translateCountry(c.country),
            value: c.country,
            continent: c.continent
          }))
          .sort((a, b) => a.text.localeCompare(b.text))
      } catch (error) {
        console.error('getCountries', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getVendors () {
      try {
        this.loading = true

        if (this.role === 'administrator') {
          const { data } = await VendorsService.getItems({
            page: 1,
            limit: 99999
          })

          this.vendors = [
            {
              text: 'ทั้งหมด',
              value: null
            },
            ...data.results.map((v) => ({
              text: v.name,
              value: v.id
            }))
          ]
        }
      } catch (error) {
        console.error('getVendors', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
